import { FC, memo } from 'react';
import { ImageSize, ProfileImageStack } from '../../ownership/ProfileImageStack';

type Prop = {
  id: string;
  firstName: string;
  lastName: string;
};

const UserRenderer: FC<{ data: Prop | Prop[] }> = (props) => {
  if (!props.data) return '-';

  const data = Array.isArray(props.data) ? props.data : [props.data];
  if (!data?.length) return '-';

  return <ProfileImageStack users={data} size={ImageSize.XS} />;
};

export default memo(UserRenderer);
